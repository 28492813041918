import {useTranslation} from "react-i18next";
import {ButtonThemes} from "../../../constants/button.constants";
import './LogInScreen.scss';
import {ContentContainer} from "../../../components/onboarding/ContentContainer/ContentContainer";
import {useEffect, useState} from "react";
import {Input} from "../../../components/lego/Input/Input";
import {Button} from "../../../components/lego/Button/Button";
import {useLocation, useNavigate} from "react-router";
import {isEmailValid, isEmptyInput} from "../../../utils/login.utils";
import {useLogInErrorLogic} from "../../../hooks/onboarding/useLogInErrorLogic";
import {FormErrorMessages, LogInWarnings} from "../../../constants/logInErrorMessages.constants";
import { resetPasswordUserAccount} from "../../../utils/firebase/auth/logInUser.utils";
import {Footer} from "../../../components/lego/Footer/Footer";
import { LOG_IN_ROUTE} from "../../../constants/routes.constants";
import { logInLogo} from "../../../assets/onBoardingImages/logInLogo";
import {PolicyAnchors} from "../../../constants/onBoardingConstants/policyAnchors.constants";

const EMAIL_ID='email'
export const ResetPassword =({nextRoute}:{nextRoute:string})=> {
    const {t} = useTranslation('onboarding');
    const navigate = useNavigate()
    const location = useLocation()
    const [email, setEmail] = useState('')

    const {errorState, setErrorState, clearErrorMessage} = useLogInErrorLogic()
    const onLogInFormSend = () => {
        if (!isEmptyInput(email)) {
            setErrorState({message: t(FormErrorMessages.EMPTY_EMAIL), errorInputId: EMAIL_ID})
            return;
        }

        if (!isEmailValid(email)) {
            setErrorState({message: t(FormErrorMessages.NOT_VALID_EMAIL), errorInputId: EMAIL_ID})
            return;
        }

        resetPasswordUserAccount({
            email,
            successfulAuthRoute: () => navigate(LOG_IN_ROUTE)
        })
    }

        const clearErrorData = (value: string, setInputValue: (inputValue: string) => void) => {
            clearErrorMessage()
            setInputValue(value)
        }

        useEffect(()=>{
            if(location.state && location.state.warningFlag) {
                location.state.warningFlag === LogInWarnings.DO_NOT_HAVE_ACCOUNT ?
                    setErrorState({message: t('logInScreen.doNotHaveAccountWarning'), errorInputId: EMAIL_ID})
                    : setErrorState({message: t("logInScreen.emailNotConfirmedWarning"), errorInputId: EMAIL_ID})
            }},[location])

        return (
            <>
                <ContentContainer>
                    <div>
                        <h4 className={'logInTitle'}>{t("resetPassword")} <img loading="lazy" src={logInLogo} alt={''}/></h4>
                    </div>
                    <div className={'logInForm'}>
                        <Input onChange={(value) => clearErrorData(value, setEmail)}
                               id={EMAIL_ID}
                               inputType={'email'}
                               labelText={t("logInScreen.email.label")}
                               placeholderText={''}
                               errorMessage={errorState.errorInputId === EMAIL_ID ? errorState.message : ''}
                        />
                    </div>

                    <div className={'logInButtons'}>
                        <Button onClick={onLogInFormSend} text={t("testBeginningScreen.continueButton")}
                                buttonTheme={ButtonThemes.DARK} width={100}
                        />
                    </div>
                </ContentContainer>

                <Footer text={<span className="">
                    {t("privatePolicyText")}
                    <a href={PolicyAnchors.TERMS}>{t("createAccountScreen.policyAnchors.terms")}</a>      &nbsp;
                    <a href={PolicyAnchors.PRIVACY}>{t("createAccountScreen.policyAnchors.privacy")}</a>{' '}      &nbsp;
                    <a href={PolicyAnchors.SUBSCRIPTION}>{t("createAccountScreen.policyAnchors.subscription")}</a>      &nbsp;
                </span>}/>
            </>
        )
    }

