import {signInWithEmailAndPassword, signOut, sendPasswordResetEmail, fetchSignInMethodsForEmail} from "firebase/auth";
import {auth, app} from "../../../firebase/firebase.config";

interface ILogInWithEmailParams {
    email:string;
    password:string;
    successfulAuthRoute:()=>void;
    newAccountRoute:()=>void;
    confirmEmailRoute:()=>void;
    googleAuth:()=>void;
}

interface ILResetPassword{
    email:string;
    successfulAuthRoute:()=>void;
}

export const logInUserAccount=async({email, password, confirmEmailRoute, successfulAuthRoute,newAccountRoute, googleAuth}:ILogInWithEmailParams)=> {
        const signInMethods = await fetchSignInMethodsForEmail(auth, email);

        // Check if "google.com" is in the list of sign-in methods
        if (signInMethods.includes("google.com")) {
            googleAuth()
            return
        } else {
            console.log(`${email} has NOT registered using Google Authentication.`);

            signInWithEmailAndPassword(auth, email, password)
                .then((userCredential) => {
                    const user = userCredential.user;
                    localStorage.setItem("accessToken", user?.accessToken)
                    if (user && user.emailVerified) {
                        successfulAuthRoute();
                        return;
                    } else if (user && !user.emailVerified) {
                        signOut(auth)
                        confirmEmailRoute();
                        return;
                    } else {
                        signOut(auth)
                        newAccountRoute();
                    }
                })
                .catch((error) => {
                    newAccountRoute();
                    const errorCode = error.code;
                    const errorMessage = error.message;
                });
        }
}

export const resetPasswordUserAccount=({email, successfulAuthRoute}:ILResetPassword)=> {
    sendPasswordResetEmail(auth, email)
        .then(() => {
            localStorage.removeItem("accessToken")
                successfulAuthRoute();
        })
        .catch((error) => {
            console.log(error?.message)
        });
}
