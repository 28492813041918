import './App.scss';
import {useEffect, useState} from "react";
import {Routes, Route, Navigate} from "react-router-dom";
import { ThemeContext } from './context/themeContext';
import {getDefaultTheme} from "./utils/theme.utils";
import {BrowserRouter} from "react-router-dom";
import {AgeCheckScreen} from "./screens/onboarding/AgeCheck/AgeCheckScreen";
import {HonestCheckScreen} from "./screens/onboarding/HonestCheck/HonestCheckScreen";
import {
    AVERAGE_TEST_RESULT_ROUTE,
    BOREDOM_DISCUSSION_ROUTE,
    CANDOR_CHECK_ROUTE,
    CONFIDENT_CHECK_ROUTE,
    CREATE_ACCOUNT_ROUTE,
    CURRENT_RELATIONSHIP_ROUTE,
    DISTANT_RELATIONSHIP_ROUTE,
    EDUCATION_TIME_CHECK_ROUTE,
    EMAIL_CHECK_ROUTE,
    EMAIL_VERIFICATION_ROUTE,
    ENTICING_ONE_ROUTE,
    GOOD_TEST_RESULT_ROUTE,
    HONEST_CHECK_ROUTE,
    INTIMATE_LIFE_ROUTE,
    LOG_IN_ROUTE,
    NEW_LIFE_JOURNEY_ROUTE,
    NEW_READINESS_ROUTE,
    PARTNER_OPENNESS_CHECK_ROUTE,
    PAY_INFO_ROUTE,
    START_QUIZ_ROUTE,
    SEXUAL_POTENTIAL_ROUTE,
    SIGN_UP_OFFER_ROUTE,
    TEST_BEGINNING_ROUTE,
    TRUSTING_RELATIONSHIP_ROUTE,
    UNSATISFACTORY_TEST_RESULT_ROUTE,
    RELATIONSHIP_ISSUE_ROUTE,
    SUBSCRIPTION_FAILED_ROUTE,
    SUBSCRIPTION_SUCCESS_ROUTE, EXISTED_EMAIL_ROUTE, FORGOT_PASSWORD_ROUTE
} from "./constants/routes.constants";
import {TestBeginningScreen} from "./screens/onboarding/TestBeginningScreen/TestBeginningScreen";
import {ConfidentCheckScreen} from "./screens/onboarding/ConfidentCheckScreen/ConfidentCheckScreen";
import {CurrentRelationshipScreen} from "./screens/onboarding/CurrentRelationshipScreen/CurrentRelationshipScreen";
import {CandorCheckScreen} from "./screens/onboarding/CandorCheckScreen/CandorCheckScreen";
import {RelationshipIssuesScreen} from "./screens/onboarding/RelationshipIssuesScreen/RelationshipIssuesScreen";
import {BoredomDiscussionScreen} from "./screens/onboarding/BoredomDiscussionScreen/BoredomDiscussionScreen";
import {PartnerOpennessScreen} from "./screens/onboarding/PartnerOpennessScreen/PartnerOpennessScreen";
import {TrustingRelationshipScreen} from "./screens/onboarding/TrustingRelationshipScreen/TrustingRelationshipScreen";
import {DistantRelationshipScreen} from "./screens/onboarding/DistantRelationshipScreen/DistantRelationshipScreen";
import {EducationTimeScreen} from "./screens/onboarding/EducationTimeScreen/EducationTimeScreen";
import {NewReadinessScreen} from "./screens/onboarding/NewReadinessScreen/NewReadinessScreen";
import {SexualPotentialScreen} from "./screens/onboarding/SexualPotentialScreen/SexualPotentialScreen";
import {EnticingOneScreen} from "./screens/onboarding/EnticingOneScreen/EnticingOneScreen";
import {IntimateLifeScreen} from "./screens/onboarding/IntimateLifeScreen/IntimateLifeScreen";
import {EmailCheckScreen} from "./screens/onboarding/EmailCheckScreen/EmailCheckScreen";
import {GoodTestResultScreen} from "./screens/onboarding/testResultsScreens/GoodTestResultcreen/GoodTestResultScreen";
import {
    AverageResultScreen
} from "./screens/onboarding/testResultsScreens/AverageTestResultScreen/AverageTestResultScreen";
import {
    UnsatisfactoryTestResultScreen
} from "./screens/onboarding/testResultsScreens/UnsatisfactoryTestResultScreen/UnsatisfactoryTestResultScreen";
import {CreateAccountScreen} from "./screens/login/CreateAccountScreen/CreateAccountScreen";
import {LogInScreen} from "./screens/login/LogInScreen/LogInScreen";
import {NewLifeJourneyScreen} from "./screens/onboarding/NewLifeJourneyScreen/NewLifeJourneyScreen";
import {HeaderContainer} from "./components/HeaderContainer/HeaderContainer";
import {SignUpOfferScreen} from "./screens/onboarding/SignUpOfferScreen/SignUpOfferScreen";
import {PayInfoScreen} from "./screens/onboarding/PayInfoScreen/PayInfoScreen";
import {AppRoutsAuth} from "./Routes";
import {EmailVerificationScreen} from "./screens/onboarding/EmailVerificationInfoScreen/EmailVerificationScreen";
import {getAuth, onAuthStateChanged,User} from "firebase/auth";
import FailedSubscription from "./screens/app/Profile/FailedPayment";
import SuccessPayment from "./screens/app/Profile/SuccessPayment";
import {GET_SUBSCRIPTION} from "./components/onboarding/PayInfoContainer/constants";
import { ExistedEmailScreen } from 'screens/onboarding/ExistedEmailScreen/ExistedEmailScreen';
import {ResetPassword} from "./screens/login/LogInScreen/ForgotPassword";


function App() {
    const [theme, setTheme] = useState(getDefaultTheme);
    const [isTrustingRelationship,setIsTrustingRelationship]=useState(false)
    const [testResultRoute,setTestResultRoute]=useState('')
    const [currentUser,setCurrentUser]=useState<User|null>(null)
    const getIsCreation = localStorage.getItem("isCreation") === 'true';
    const [checkSubscription, setCheckSubscription] = useState(false)
    const location = window.location
    const [hasSubscription, setHasSubscription] = useState(null)
    const [loading, setLoading] = useState(null)
    useEffect(() => {
        onAuthStateChanged(
            getAuth(),
            async (user) => {
                setCurrentUser(user)
                if(user?.email) localStorage.setItem("email", user?.email)
                if(user) checkIfSubscription()
            },
        );
    })

    useEffect(() => {
        const params = new URLSearchParams(location.search);
        const fbclid = params.get('fbclid');
        if (fbclid) {
            localStorage.setItem('fbclid', fbclid)
        }
        const clickid = params.get('clickid');
        if (clickid) {
            localStorage.setItem('clickid', clickid)
        }
        const client_id = params.get('client_id');
        if (client_id) {
            localStorage.setItem('clientId', client_id)
        }
    }, [location]);
    useEffect(() => {
        if(currentUser?.accessToken){
            localStorage.setItem("accessToken", currentUser.accessToken)
        }
    },[currentUser])
    useEffect(() => {
        const handleScreenshotEvent = (event: any) => {
            // Check for macOS and specific key combinations
            if (navigator.platform.toUpperCase().includes('MAC') &&
                (event.metaKey || event.key === 'Meta' || event.keyCode === 91 || event.keyCode === 93)) {
                console.log('Screenshot detected on macOS!');
                // Handle accordingly
            }
        };

        document.addEventListener('keydown', handleScreenshotEvent);

        return () => {
            document.removeEventListener('keydown', handleScreenshotEvent);
        };
    }, []);

    const checkIfSubscription = async () => {
        if(!currentUser?.accessToken || !!checkSubscription) return
        const res = await fetch(GET_SUBSCRIPTION, {method: "GET",  credentials: 'include', headers: {Authorization: 'Bearer ' + currentUser?.accessToken, "Access-Control-Allow-Origin": "*"}})
            .then((response) => response.json())
        setCheckSubscription(true)
        setHasSubscription(res)
    }

    useEffect(() => {
        checkIfSubscription()
    }, [currentUser]);


    useEffect(() => {
        const handleScreenshotEvent = (event: any) => {
            // Check for Windows and specific key combinations
            if (navigator.platform.toUpperCase().includes('WIN') &&
                (event.key === 'PrintScreen' || (event.altKey && event.key === 'PrintScreen'))) {
                console.log('Screenshot detected on Windows!');
                // Handle accordingly
            }
        };

        document.addEventListener('keydown', handleScreenshotEvent);

        return () => {
            document.removeEventListener('keydown', handleScreenshotEvent);
        };
    }, []);

  return (
      <ThemeContext.Provider value={{ theme, setTheme }}>
          <BrowserRouter>
              <div className={`app ${theme}`}>
                  {(!currentUser?.email || getIsCreation || hasSubscription === null )? <div className={`appContainer ${theme}`}>
                      <HeaderContainer isTrustingRelationship={isTrustingRelationship} />
                      <Routes>
                          <Route path="/" element={<LogInScreen nextRoute={'/'}/> } />
                          <Route path={LOG_IN_ROUTE} element={<LogInScreen nextRoute={'/'}/> } />
                          <Route path={FORGOT_PASSWORD_ROUTE} element={<ResetPassword nextRoute={'/'}/> } />
                          <Route path={START_QUIZ_ROUTE} element={<AgeCheckScreen nextRoute={HONEST_CHECK_ROUTE}/>}/>
                          <Route path={HONEST_CHECK_ROUTE}
                                 element={<HonestCheckScreen nextRoute={TEST_BEGINNING_ROUTE}/>}/>
                          <Route path={TEST_BEGINNING_ROUTE}
                                 element={<TestBeginningScreen nextRoute={CONFIDENT_CHECK_ROUTE}/>}/>
                          <Route path={CONFIDENT_CHECK_ROUTE}
                                 element={<ConfidentCheckScreen nextRoute={CURRENT_RELATIONSHIP_ROUTE}/>}/>
                          <Route path={CURRENT_RELATIONSHIP_ROUTE}
                                 element={<CurrentRelationshipScreen nextRoute={CANDOR_CHECK_ROUTE}/>}/>
                          <Route path={CANDOR_CHECK_ROUTE}
                                 element={<CandorCheckScreen nextRoute={RELATIONSHIP_ISSUE_ROUTE}/>}/>
                          <Route path={RELATIONSHIP_ISSUE_ROUTE}
                                 element={<RelationshipIssuesScreen nextRoute={BOREDOM_DISCUSSION_ROUTE}/>}/>
                          <Route path={BOREDOM_DISCUSSION_ROUTE}
                                 element={<BoredomDiscussionScreen nextRoute={PARTNER_OPENNESS_CHECK_ROUTE}/>}/>
                          <Route path={PARTNER_OPENNESS_CHECK_ROUTE} element={
                              <PartnerOpennessScreen setIsTrustingRelationship={setIsTrustingRelationship}
                                                     nextRoutes={[TRUSTING_RELATIONSHIP_ROUTE, DISTANT_RELATIONSHIP_ROUTE]}/>
                          }/>
                          <Route path={TRUSTING_RELATIONSHIP_ROUTE}
                                 element={<TrustingRelationshipScreen nextRoute={EDUCATION_TIME_CHECK_ROUTE}/>}/>
                          <Route path={DISTANT_RELATIONSHIP_ROUTE}
                                 element={<DistantRelationshipScreen nextRoute={EDUCATION_TIME_CHECK_ROUTE}/>}/>
                          <Route path={EDUCATION_TIME_CHECK_ROUTE}
                                 element={<EducationTimeScreen nextRoute={NEW_READINESS_ROUTE}/>}/>
                          <Route path={NEW_READINESS_ROUTE}
                                 element={<NewReadinessScreen nextRoute={SEXUAL_POTENTIAL_ROUTE}/>}/>
                          <Route path={SEXUAL_POTENTIAL_ROUTE}
                                 element={<SexualPotentialScreen nextRoute={ENTICING_ONE_ROUTE}/>}/>
                          <Route path={ENTICING_ONE_ROUTE}
                                 element={<EnticingOneScreen nextRoute={INTIMATE_LIFE_ROUTE}/>}/>
                          <Route path={INTIMATE_LIFE_ROUTE}
                                 element={<IntimateLifeScreen setTestResultRoute={setTestResultRoute}
                                                              nextRoute={EMAIL_CHECK_ROUTE}/>}/>
                          <Route path={EMAIL_CHECK_ROUTE} element={<EmailCheckScreen nextRoute={testResultRoute|| GOOD_TEST_RESULT_ROUTE}/>}/>
                          <Route path={GOOD_TEST_RESULT_ROUTE} element={<GoodTestResultScreen nextRoute={PAY_INFO_ROUTE}/>}/>
                          <Route path={AVERAGE_TEST_RESULT_ROUTE} element={<AverageResultScreen nextRoute={PAY_INFO_ROUTE}/>}/>
                          <Route path={UNSATISFACTORY_TEST_RESULT_ROUTE}
                                 element={<UnsatisfactoryTestResultScreen nextRoute={PAY_INFO_ROUTE}/> } />
                          <Route path={PAY_INFO_ROUTE} element={<PayInfoScreen nextRoute={CREATE_ACCOUNT_ROUTE}/> } />
                          <Route path={CREATE_ACCOUNT_ROUTE} element={<CreateAccountScreen nextRoute={SIGN_UP_OFFER_ROUTE}/> } />
                          <Route path={EMAIL_VERIFICATION_ROUTE} element={<EmailVerificationScreen nextRoute={SIGN_UP_OFFER_ROUTE}/> } />
                          <Route path={EXISTED_EMAIL_ROUTE} element={<ExistedEmailScreen nextRoute={LOG_IN_ROUTE}/> } />
                          <Route path={SIGN_UP_OFFER_ROUTE} element={<SignUpOfferScreen nextRoute={NEW_LIFE_JOURNEY_ROUTE}/> } />
                          <Route path={NEW_LIFE_JOURNEY_ROUTE} element={<NewLifeJourneyScreen nextRoute={LOG_IN_ROUTE}/> } />
                          <Route path={LOG_IN_ROUTE} element={<LogInScreen nextRoute={'/'}/>}/>
                          <Route path={SUBSCRIPTION_FAILED_ROUTE} element={<FailedSubscription isHeader={false} nextRoute={PAY_INFO_ROUTE}/>} />
                          <Route path={SUBSCRIPTION_SUCCESS_ROUTE} element={<SuccessPayment isHeader={false} nextRoute={LOG_IN_ROUTE}/>}/>
                          {/*<Route path="*" element={<Navigate to="/" replace />} />*/}
                      </Routes>
                  </div> : currentUser?.email && !!hasSubscription ? (
                      <AppRoutsAuth theme={theme}/>
                  ) : <div className={`appContainer ${theme}`}>
                      <HeaderContainer isTrustingRelationship={isTrustingRelationship}/>
                      <Routes>
                          <Route path={PAY_INFO_ROUTE} element={<PayInfoScreen nextRoute={CREATE_ACCOUNT_ROUTE}/>}/>
                          <Route path={LOG_IN_ROUTE} element={<LogInScreen nextRoute={'/'}/>}/>
                          <Route path={FORGOT_PASSWORD_ROUTE} element={<ResetPassword nextRoute={'/'}/> } />
                          <Route path={SUBSCRIPTION_FAILED_ROUTE} element={<FailedSubscription isHeader={false} nextRoute={PAY_INFO_ROUTE}/>}/>
                          <Route path={SUBSCRIPTION_SUCCESS_ROUTE} element={<SuccessPayment isHeader={false} nextRoute={LOG_IN_ROUTE}/>}/>
                          <Route path="/" element={<PayInfoScreen nextRoute={CREATE_ACCOUNT_ROUTE}/>}/>
                          {/*<Route path="*" element={<Navigate to="/" replace />} />*/}
                      </Routes></div>}
              </div>
          </BrowserRouter>
      </ThemeContext.Provider>
  );
}

export default App;
