import './style.scss'
import {ReactComponent as LoaderSvg} from "assets/app/loader.svg";
import {useTranslation} from "react-i18next";

const Loader = () => {
    const onboarding= useTranslation('onboarding')
    return (
        <div className="loader-overlay">
            <div className="loader column align-center">
                <div className="typography-24">{onboarding.t("payInfoScreen.payment_in_progress")}</div>
                <div className="svg-loader mt-24">
                <LoaderSvg />
                </div>
            </div>
        </div>
    );
};

export default Loader;