import {Trans, useTranslation} from "react-i18next";
import {ButtonThemes} from "../../../constants/button.constants";
import './IntimateLifeScreen.scss';
import {ContentContainer} from "../../../components/onboarding/ContentContainer/ContentContainer";
import {NextRouteButton} from "../../../components/NextRouteButton/NextRouteButton";
import {
    AVERAGE_TEST_RESULT_ROUTE,
    GOOD_TEST_RESULT_ROUTE,
    UNSATISFACTORY_TEST_RESULT_ROUTE
} from "../../../constants/routes.constants";
import {MIXPANEL_API} from "../../../components/onboarding/PayInfoContainer/constants";

interface IIntimateLifeProps {
    nextRoute:string,
    setTestResultRoute:(route:string)=>void
}

interface IIntimateLifeOptions {
    option: string;
    testResultRoute :string;
}

const answers = [
    'Excellent, couldn\'t be better.',
    'Good, but there\'s room for improvement.',
    'Average, it\'s neither great nor terrible.',
    'Need work, we\'re facing challenges.',
    'Unsatisfactory, there are significant issues.'
]
export const IntimateLifeScreen =({nextRoute, setTestResultRoute}:IIntimateLifeProps)=>{
    const { t } = useTranslation('onboarding');

    const intimateLifeOptions:IIntimateLifeOptions[]= [
        {option: t("intimateLifeCheckScreen.excellentOptionText"),
            testResultRoute: GOOD_TEST_RESULT_ROUTE},
        {option: t("intimateLifeCheckScreen.goodOptionText"),
            testResultRoute: GOOD_TEST_RESULT_ROUTE},
        {option:t("intimateLifeCheckScreen.averageMinOptionText"),
            testResultRoute: AVERAGE_TEST_RESULT_ROUTE},
        {option:t("intimateLifeCheckScreen.needWorkOptionText"),
            testResultRoute: UNSATISFACTORY_TEST_RESULT_ROUTE},
        {option: t("intimateLifeCheckScreen.unsatisfactoryOptionText"),
            testResultRoute: UNSATISFACTORY_TEST_RESULT_ROUTE}];

    const trackMixpanel = async(option: number) => {
        const token = localStorage.getItem("accessToken")
        const fbclid = localStorage.getItem("fbclid")
        const clickid = localStorage.getItem("clickid")
        const email = localStorage.getItem("email")
        const clientId = localStorage.getItem("clientId")
        const data = {
            event: 'onboarding_question_responded',
            data: {
                onboarding_step_number: 12,
                question: 'Rate your intimate life with your partner.',
                respond: answers[option],
            },
            fbclid: fbclid,
            clickid: clickid,
            clientId: clientId,
            email: email,
        }
        await fetch(MIXPANEL_API, {method: "POST", headers: {
                Authorization: 'Bearer ' + token,
                "Content-Type": "application/json"
            }, body: JSON.stringify(data)})
    }

    const setFinalTestResultRoute= (route:string)=>{
        setTestResultRoute(route)
    }

    return (
        <>
            <ContentContainer>
                <h4 className={'intimateLifeQuestionTitle'}>{t("intimateLifeCheckScreen.title")}</h4>
                <p className={'intimateLifeQuestion'}>
                    <Trans t={t}>{t("intimateLifeCheckScreen.rateText")}</Trans>
                </p>
            </ContentContainer>
            <div>
                {intimateLifeOptions.map(({option,testResultRoute}, idx)=>{
                    return <NextRouteButton key={option} option={option}
                                            route={nextRoute}
                                            buttonTheme={ButtonThemes.DARK}
                                            className={'intimateLifeCheckOptionButton'}
                                            onClick={()=>{
                                                trackMixpanel(idx)
                                                setFinalTestResultRoute(testResultRoute)}}/>
                })}
            </div>
        </>
    )
}
