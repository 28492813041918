import {Trans, useTranslation} from "react-i18next";
import {ButtonThemes} from "../../../constants/button.constants";
import './EducationTimeScreen.scss';
import {ContentContainer} from "../../../components/onboarding/ContentContainer/ContentContainer";
import {NextRouteButton} from "../../../components/NextRouteButton/NextRouteButton";
import {OnBoardingImage} from "../../../components/onboarding/OnBoardingImage/OnBoardingImage";
import {educationTimePicture} from "../../../assets/onBoardingImages";
import {MIXPANEL_API} from "../../../components/onboarding/PayInfoContainer/constants";

const answers = [
    "10 min/day",
    "20 min/day",
    "30 min/day",
    "60 min/day"
]
export const EducationTimeScreen =({nextRoute}:{nextRoute:string})=>{
    const { t } = useTranslation('onboarding');

    const relationshipIssuesOptions:string[]= [
        t("educationTimeCheckScreen.tenMinOptionText"),
        t("educationTimeCheckScreen.twentyMinOptionText"),
        t("educationTimeCheckScreen.thirtyMinOptionText"),
        t("educationTimeCheckScreen.sixtyMinOptionText"),];

    const trackMixpanel = async(option: number) => {
        const token = localStorage.getItem("accessToken")
        const fbclid = localStorage.getItem("fbclid")
        const clickid = localStorage.getItem("clickid")
        const email = localStorage.getItem("email")
        const clientId = localStorage.getItem("clientId")
        const data = {
            event: 'onboarding_question_responded',
            data:{
                onboarding_step_number: 8,
                question: 'How much time are you ready to spend learning how to transform your Sex life?',
                respond: answers[option],
            },
            fbclid: fbclid,
            clickid: clickid,
            clientId: clientId,
            email: email
        }
        await fetch(MIXPANEL_API, {method: "POST", headers: {
                Authorization: 'Bearer ' + token,
                "Content-Type": "application/json"
            }, body: JSON.stringify(data)})
    }

    return (
        <>
            <ContentContainer>
                <OnBoardingImage imageSrc={educationTimePicture} />
            </ContentContainer>
            <p className={'educationTimeCheckQuestion'}>
                <Trans t={t}>{t("educationTimeCheckScreen.question")}</Trans>
            </p>
            <div>
                {relationshipIssuesOptions.map((optionText, idx)=>{
                    return <NextRouteButton key={optionText} option={optionText}
                                            route={nextRoute}
                                            onClick={() => trackMixpanel(idx)}
                                            buttonTheme={ButtonThemes.DARK}
                                            className={'educationTimeOptionButton'} />
                })}
            </div>
        </>
    )
}
