import './Header.scss';
import {FlagDropdown} from "../FlagDropdown/FlagDropdown";
import {useTranslation} from "react-i18next";
import {AppTheme} from "../AppTheme/AppTheme";
import {LocationBackArrow} from "../LocationBackArrow/LocationBackArrow";
import {useNavigate} from "react-router";

export const Header =({routesWithBackArrow}:{routesWithBackArrow:string[]})=>{
    const { t } = useTranslation('onboarding');
    const navigate = useNavigate()

      return <header className="header">
          <LocationBackArrow routesWithBackArrow={routesWithBackArrow}/>
          <h4 onClick={() => navigate('/')}>{t("logo").toString().toUpperCase()}</h4>
          <div className={'headerIconsContainer'}>
            <FlagDropdown/>
            <AppTheme/>
          </div>
      </header>
 }
