import {useTranslation} from "react-i18next";
import {ContentContainer} from "../../../components/onboarding/ContentContainer/ContentContainer";
import {ButtonThemes} from "../../../constants/button.constants";
import './PartnerOpennessScreen.scss';
import {NextRouteButton} from "../../../components/NextRouteButton/NextRouteButton";
import {MIXPANEL_API} from "../../../components/onboarding/PayInfoContainer/constants";

interface IOpennessOptionsWithRoutes {
    option: string;
    route: string;
    buttonTheme: ButtonThemes;
    onClickFn: (idx: number)=>void
}

interface IPartnerOpenness {
    setIsTrustingRelationship:(isTrustingRelationship:boolean)=>void,
    nextRoutes:string[]
}

const answers = [
    "Yes",
    "No"
]

export const PartnerOpennessScreen =({setIsTrustingRelationship,nextRoutes}:IPartnerOpenness)=>{
    const { t } = useTranslation('onboarding');
    const trackMixpanel = async(option: number) => {
        const token = localStorage.getItem("accessToken")
        const fbclid = localStorage.getItem("fbclid")
        const clickid = localStorage.getItem("clickid")
        const email = localStorage.getItem("email")
        const clientId = localStorage.getItem("clientId")
        const data = {
            event: 'onboarding_question_responded',
            data:{
                onboarding_step_number: 6,
                question: 'Can you say that you\'re completely open with your partner 100%?',
                respond: answers[option],
            },
            fbclid: fbclid,
            clickid: clickid,
            clientId: clientId,
            email: email
        }
        await fetch(MIXPANEL_API, {method: "POST", headers: {
                Authorization: 'Bearer ' + token,
                "Content-Type": "application/json"
            }, body: JSON.stringify(data)})
    }
    const partnerOpennessOptionsWithRoutes:IOpennessOptionsWithRoutes[] = [
        {option:t("checkPartnerOpennessScreen.agreeButtonText"),route:nextRoutes[0],
            buttonTheme:ButtonThemes.DARK, onClickFn: (idx: number)=> {
                trackMixpanel(idx)
            setIsTrustingRelationship(true)

            }},
        {option:t("checkPartnerOpennessScreen.disagreeButtonText"),route:nextRoutes[1],
            buttonTheme:ButtonThemes.LIGHT, onClickFn: (idx: number)=> {
            setIsTrustingRelationship(false)
                trackMixpanel(idx)
            }}];



    return (
        <>
            <ContentContainer>
                <h4 className={'partnerOpennessTitle'}>{t("checkPartnerOpennessScreen.title")}</h4>
                <p className={'partnerOpennessText'}>{t("checkPartnerOpennessScreen.text")}</p>
                <h6 className={'partnerOpennessQuestion'}>{t("checkPartnerOpennessScreen.question")}</h6>
            </ContentContainer>
            <div>
                {partnerOpennessOptionsWithRoutes.map(
                    ({onClickFn, option,route, buttonTheme},index)=>{
                    return <NextRouteButton
                        key={option}
                        onClick={() => onClickFn(index)}
                        option={option}
                        route={route}
                        buttonTheme={buttonTheme}
                        className={'partnerOpennessOptionButton'}/>
                })}
            </div>
        </>
    )
}
